<template>
    <div>
        <v-container>
            <v-toolbar elevation="1" dark color="primary">
                <v-toolbar-title>
                    Detalle de la referencia {{$route.params.folio}}
                </v-toolbar-title>

                <v-spacer/>
                <v-icon @click="$router.push({ path: '/referencias' })">mdi-arrow-left</v-icon>
            </v-toolbar>

            <v-alert
                type="error"
                v-if="total==0 && !loadingDatos"
                class="mt-3"
            >
                Problemas para cargar datos o esta solicitud no existe
            </v-alert>
            <v-alert
                type="error"
                v-if="datosOrig.status==99"
                class="mt-3"
                text
            >
                Solicitud cancelada el {{datosOrig.fechaCancela}} por {{datosOrig.cancela}}<br/>
                {{datosOrig.observacionesCancela}}
            </v-alert>
            <v-row no-gutters class="mt-2" v-if="total!=0 && !loadingDatos">
                <v-col  xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <v-sheet elevation="2" class="mx-1 pa-3" height="100%">
                        <div class="d-flex flex-row">
                                <v-text-field
                                    class="pr-2"
                                    label="Tipo de referencia"
                                    hide-details
                                    v-model="tipoRef(datos.tipoRef).tipo"
                                    readonly
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            :color="tipoRef(datos.tipoRef).color"
                                        >
                                            {{tipoRef(datos.tipoRef).icon}}
                                        </v-icon>
                                    </template>
                                </v-text-field>

                                <v-select
                                    class="pl-2"
                                    label="Estado"
                                    hide-details
                                    v-model="datos.status"
                                    :items="$store.state.catalogos.estadosReferencias"
                                    background-color="grey lighten-3"
                                    @change="cambiaEstado"
                                />
                        </div>
                        <v-text-field
                            label="Unidad que refiere"
                            hide-details
                            v-model="unidad"
                            readonly
                        />

                        <v-text-field
                            label="Médico de referecia"
                            hide-details
                            v-model="datos.medico"
                            readonly
                        />

                        <div class="d-flex flex-row">
                            <v-text-field
                                class="pr-2"
                                label="Fecha de solicitud"
                                hide-details
                                v-model="datos.fechaSolicitud"
                                readonly
                            />

                            <v-text-field
                                class="pl-2"
                                label="Tipo de servicio"
                                hide-details
                                v-model="tipoServicio"
                                readonly
                            />
                        </div>
                    </v-sheet>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <v-sheet elevation="2" class="pa-3 mx-1" height="100%">
                        <v-text-field
                            label="Paciente"
                            hide-details
                            v-model="datos.paciente"
                            readonly
                            append-icon="mdi-magnify"
                            background-color="grey lighten-3"
                            @click:append="muestraBuscaPaciente"
                        >
                        </v-text-field>

                        <div class="d-flex flex-row">
                            <v-text-field
                                class="pr-2"
                                label="Fecha de nacimiento"
                                hide-details
                                v-model="datos.fechaNacimiento"
                                readonly
                            />
                            <v-text-field
                                class="px-2"
                                label="Edad"
                                hide-details
                                v-model="datos.edad"
                                readonly
                            />
                            <v-text-field
                                class="pl-2"
                                label="Sexo"
                                hide-details
                                v-model="datos.sexo"
                                readonly
                            />
                        </div>

                        <v-text-field
                            v-if="datos.tipo == 2"
                            label="Cita asignada"
                            hide-details
                            v-model="datos.cita"
                            type="number"
                            background-color="grey lighten-3"
                        >
                            <template v-slot:append-outer>
                                <v-btn @click="guardaCita" icon>
                                    <v-icon
                                        color='green'
                                    >
                                        mdi-check
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>

                        <div class="d-flex flex-row justify-space-between align-center">
                            <div>
                                <v-btn icon x-large @click="dialogObs = true">
                                    <v-icon>mdi-android-messages</v-icon>
                                </v-btn>
                            </div>
                            <div class="mt-2 text-right">
                                <span class="text-caption "><b>Fecha de creación:</b> {{datos.dateCreated}} ({{datos.crea}})</span><br/>
                                <span v-if="datos.actualiza" class="text-caption"><b>Última actualización:</b> {{datos.lastUpdated}} ({{datos.actualiza}})</span>
                            </div>
                        </div>
                    </v-sheet>
                </v-col>
                <v-col cols="12">
                    <v-sheet elevation="2" class="mt-2 mx-1 pa-2">
                        <v-textarea
                            label="Diagnósticos"
                            v-model="datos.diagnosticos"
                        />
                        <v-textarea
                            label="Motivo de la solicitud"
                            v-model="datos.motivo"
                        />
                        <v-textarea
                            label="Observaciones"
                            v-model="datos.observaciones"
                        />
                    </v-sheet>
                </v-col>
            </v-row>
            <busca-paciente
                :dialogBuscaPaciente.sync="dialogBuscaPaciente"
                :paciente.sync="datos.cip"
                :todos="true"
            />

            <comentarios-referencias
                :dialogObs.sync="dialogObs"
                :solicitud="datos.id"
            />

        </v-container>
    </div>
</template>

<script>
import { diff } from 'deep-object-diff'

import BuscaPaciente from '@/components/hospital/buscaPaciente'
import ComentariosReferencias from '@/components/referencias/ComentariosReferencias'

export default {

    components:{
        BuscaPaciente,
        ComentariosReferencias,
    },

    mounted(){
        this.cargaDetalle()
    },
    data:()=>({
        loadingDatos:false,
        datos:{},
        datosOrig:{},
        total:0,

        dialogBuscaPaciente:false,
        dialogObs:false,

    }),
    methods:{

        cambiaEstado(){

            if (this.datos.status == 99){
                this.$swal.fire({
                    title: 'Cancelar',
                    text: 'Indique el motivo para cancelar esta solicitud',
                    input: 'text',
                    icon: 'question',
                    showCancelButton: true,
                    cancelButtonText: 'No deseo cancelar',
                    confirmButtonText: 'Si deseo cancelar',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !this.$swal.isLoading(),
                    preConfirm: (observaciones) => {
                        this.$swal.fire({
                            title: 'Cancelar',
                            text: "¿Desea Cancelar?",
                            icon: 'question',
                            showCancelButton: true,
                            cancelButtonText: 'NO cancelar',
                            confirmButtonText: 'SI cancelar',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.datos.observacionesCancela=observaciones
                                this.guarda()
                            } else {
                                this.datos = {...this.datosOrig}
                            }
                        })

                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.guarda()
                    } else {
                        this.datos = {...this.datosOrig}
                    }
                })

            } else{
                this.$swal.fire({
                    title: 'Cambiar estado',
                    text: "¿Desea cambiar el estado de esta solicitud?",
                    icon: 'question',
                    showCancelButton: true,
                    cancelButtonText: 'NO cambiar',
                    confirmButtonText: 'SI cambiar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.guarda()
                    } else {
                        this.datos = {...this.datosOrig}
                    }
                })
            }
        },

        async cargaDetalle(){
            this.loadingDatos=true
            try{
                let rf = await this.$http({
                    url:'/referencias/list',
                    method:'GET',
                    params:{folio:this.$route.params.folio},
                })
                this.datosOrig = rf.data.root[0]
                this.datos = {...this.datosOrig}
                this.total = rf.data.total
                this.loadingDatos=false

            }catch(err){
                this.loadingDatos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async guarda(){
            //console.log(this.datos)
            this.loadingDatos = true
            let params = diff(this.datosOrig, this.datos)
            params.id = this.datos.id

            try{
                let rf = await this.$http({
                    url:'/referencias/guarda',
                    method:'POST',
                    params:params
                })
                this.loadingDatos = false

                if (rf.data.estado == true){
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Guardado',
                        text: 'Datos actualizados correctamente',
                    })
                    this.cargaDetalle()
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            }catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingDatos = false
            }
        },

        async guardaCita(){
            
            try{
                let cit = await this.$http({
                    url:'consulta/getCita',
                    method: 'GET',
                    params:{id:this.datos.cita}
                })

                let dt = cit.data.root

                if (cit.data.estado == 'true'){
                    this.$swal.fire({
                        title: 'Asignar CITA',
                        html: "Los datos de esta cita son:<br/><b>Paciente: </b>"+dt.paciente+'<br/><b>Fecha:</b> '+dt.fecha,
                        icon: 'question',
                        showCancelButton: true,
                        cancelButtonText: 'NO asignar',
                        confirmButtonText: 'SI asignar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.datos.cip = dt.cip
                            this.guarda()
                        } else {
                            this.cargaDetalle()
                        }
                    })
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'Datos incorrectos',
                        text: 'No se pueden consultar los datos',
                    })
                }
            }
            catch{
                this.$swal.fire({
                    icon: 'warning',
                    title: 'No se puede consultar',
                    text: 'No se puede realizar la consulta. Revise de nuevo',
                })
            }

            /*
            this.$swal.fire({
                title: 'Asignar CITA',
                text: "¿Desea registrar CITA de esta solicitud?",
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'NO asignar',
                confirmButtonText: 'SI asignar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.guarda()
                } else {
                    this.cargaDetalle()
                }
            })
            */
            
        },

        guardaPaciente(){
            this.$swal.fire({
                title: 'Asignar PACIENTE',
                text: "¿Desea asignar un PACIENTE a esta solicitud?",
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'NO asignar',
                confirmButtonText: 'SI asignar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.guarda()
                } else {
                    this.cargaDetalle()
                }
            })
        },

        muestraBuscaPaciente(){
            this.dialogBuscaPaciente=true
        },
        tipoRef(t){
            let tipo = ''
            let color = ''
            let icon = ''

            switch (t){
                case "r":
                    tipo = 'Recibida'
                    color = 'green'
                    icon = 'mdi-email-receive-outline'
                    break

                case "e":
                    tipo = 'Enviada'
                    color = 'orange'
                    icon = 'mdi-email-send-outline'
                    break
            }

            return ({tipo:tipo, icon:icon, color:color})
        },
        
        
    },
    computed:{
        unidad(){
            return '('+this.datos.unidadClues+') '+this.datos.unidadNombre
        },
        tipoServicio(){
            let cadena = ''
            if (this.datos.tipo){
                cadena = this.$store.state.catalogos.tiposReferencias.find(x=>x.value == this.datos.tipo).text
                if (this.datos.tipo == 2){
                    cadena = cadena + ' ('+this.datos.servicioNombre+')'
                }
            }
            return cadena
        }
        
    },
    watch:{
        dialogBuscaPaciente(){
            if (!this.dialogBuscaPaciente && this.datos.cip != this.datosOrig.cip){
                this.guardaPaciente()
            }
        },

    },
}
</script>

<style>

</style>