<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
        persistent
    >
        <v-sheet >

            <v-toolbar dark color="primary">
                <v-toolbar-title>Anotaciones de la solicitud</v-toolbar-title>
                <v-spacer/>
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close-box</v-icon>
                </v-btn>
            </v-toolbar>

            
            <div class="d-flex justify-end pt-2 px-2">

                <v-expand-transition>
                    <v-btn fab dark small color="success" @click="abreNuevaEntrada" v-if="!nuevoTextoArea">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-expand-transition>

            </div>

            <v-expand-transition>
                <div v-if="nuevoTextoArea" class="px-2">
                    <v-textarea
                        
                        v-model="nuevoTexto"
                    />
                    <div class="d-flex flex-row justify-end">
                        <v-btn text color="error" @click="cancela">Cancelar</v-btn>
                        <v-btn color="success" @click="agrega" :loading="loadingNuevoTexto">Agregar</v-btn>
                    </div>

                </div>
            </v-expand-transition>

            <v-alert
                type="info"
                v-if="totalDet==0 && !loadingCargaDetalles"
                text
                class="mt-3"
                
            >
                No hay anotaciones registradas
            </v-alert>

            <v-list two-line dense>
                
                <v-list-item-group>
                    <v-list-item
                    
                        v-for="(item, i) in detalles"
                        :key="i"
                    >
                        <template v-slot:default>

                            <v-list-item-content>

                                <v-list-item-title>
                                    <v-icon x-small color="primary" v-if="item.automatico">
                                        mdi-star
                                    </v-icon>
                                    <span style="white-space: pre-wrap"> {{item.movimiento}}</span>
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                    <div class="text-right">Por {{item.registra}} el {{item.dateCreated}}</div>
                                </v-list-item-subtitle>
                                
                                <v-divider/>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-pagination
                circle
                v-model="pageDet"
                :length="pageLengthDet"
                total-visible="7"
                @input="cargaDetalles"
                
            ></v-pagination>

        </v-sheet>
        
    </v-dialog>
</template>

<script>
export default {

    props:{
        dialogObs:Boolean,
        solicitud:Number,
    },
    data:()=>({
        nuevoTexto:'',
        nuevoTextoArea:false,
        loadingNuevoTexto:false,

        loadingCargaDetalles:false,
        totalDet:0,
        detalles:[],

        limitDet:5,
        pageDet:1,
    }),
    methods:{
        abreNuevaEntrada(){
            this.nuevoTextoArea = true
        },
        cancela(){
            this.nuevoTexto=''
            this.nuevoTextoArea=false
        },
        async agrega(){
            this.loadingNuevoTexto = true
            try{
                let comment = await this.$http({
                    url:'/referencias/agregaDetalle',
                    method:'POST',
                    params:{
                        solicitud:this.solicitud,
                        movimiento:this.nuevoTexto,
                    }

                })

                this.loadingNuevoTexto = false
                
                if (comment.data.estado){
                    this.nuevoTexto=''
                    this.nuevoTextoArea=false
                    this.cargaDetalles()
                
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            }catch(err){
                this.loadingNuevoTexto = false
                this.$store.dispatch('security/revisaError',err)
            }
            this.nuevoTexto=''
            this.nuevoTextoArea=false

            //console.log(this.solicitud)
        },
        async cargaDetalles(){
            this.loadingCargaDetalles=true
            try{
                let det = await this.$http({
                    url:'/referencias/listDetalles',
                    method:'GET',
                    params:{
                        referencia:this.solicitud,
                        limit: this.limitDet,
                        start: (this.pageDet -1) * this.limitDet,
                    }
                })
                this.totalDet=det.data.total
                this.detalles=det.data.root
                this.loadingCargaDetalles=false
            }catch(err){
                this.loadingCargaDetalles=false
                this.$store.dispatch('security/revisaError',err)
            }
        }
    },
    computed:{
        dialog:{
            get(){
                return this.dialogObs
            },
            set(val){
                this.$emit('update:dialogObs',val)
            },
        },
        pageLengthDet: function(){
            return (Math.ceil(this.totalDet / this.limitDet));
        },
    },
    watch:{
        dialog(){
            if (!this.dialog){
                this.nuevoTexto=''
                this.nuevoTextoArea=false
                this.detalles=[]
            } else {
                this.cargaDetalles()
            }
        }
    },
}
</script>

<style>

</style>